<template>
  <div v-if="isLoaded">
    <!-- 内容块 -->
    <div class="detail-body">
      <!-- 标题 -->
      <div class="detail-title">{{data.companyName}}</div>

      <!-- 内容 -->
      <div class="detail-content" v-viewer v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
import loading from '@/mixins/loading'

export default {
  name: 'MemberDetail',

  data () {
    return {
      id: '',
      data: {}
    }
  },

  mixins: [loading],

  created () {
    this.showLoading()
    this.id = this.$route.query.id
    this.getMemberByID()
  },

  beforeRouteLeave (to, from, next) {
    to.meta.keepAlive = to.path === '/member-list'
    next()
  },

  methods: {
    // 获取信息
    getMemberByID () {
      this.api.getMemberByID({ id: this.id }).then(res => {
        this.data = res.data
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getMemberByID() })
      }).finally(() => {
        this.hideLoading()
      })
    }
  }
}
</script>

<style scoped>
</style>
